.carousel-container {
  max-width: 100%; 
  margin: 0 auto;
}

.carousel {
  width: 100%;
}

.carousel-slide img{
  object-fit: cover !important;
  width: 100%;
  height: 500px !important;
}

.carousel .carousel-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel .carousel-slide img {
  max-width: 100%;
  height: auto;
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
}

.carousel-container .carousel .control-arrow::before {
  opacity: 1;
}

.carousel-arrow.prev {
  left: 10px;
}

.carousel-arrow.next {
  right: 10px;
}
.carousel .thumbs {
  text-align: center;
  overflow: scroll;
}
.carousel .thumbs::-webkit-scrollbar{
  display: none;
}

.carousel-container .carousel .control-dots .dot{
    width: 38px;
    height: 3px;
    border-radius: 0;
    box-shadow: none;
}

/* .carousel-inner-text{
  animation: slideUp 3s;
} */

/* @keyframes slideUp{
  0%{
    transform: translateY(150px);
    opacity: 0;
  }
  100%{
    transform: translateY(0);
    opacity: 1;
    transition: 0.8ms ease;
  }
} */

/* .carousel-inner-text-first,.carousel-inner-text-second, .carousel-inner-text-third {
  opacity: 0;
  transform: translateY(150px);
} 

.carousel-container .carousel .slider-wrapper .slider .selected {
  .carousel-inner-text-first{
    opacity: 1;
    transform: translateY(0);
    transition: 0.8s ease, transform 0.8s ease;
  }
  .carousel-inner-text-second{
    opacity: 1;
    transform: translateY(0);
    transition: 1.1s ease, transform 1.1s ease;
  }
  .carousel-inner-text-third{
    opacity: 1;
    transform: translateY(0);
    transition: 1.4s ease, transform 1.4s ease;
  }
} */

.carousel-container .carousel .thumbs .thumb {
  border: solid 3px #333;
} 

.carousel-container .carousel .thumbs .selected{
  border: solid 3px white;
}

.carousel-inner-text-second{
  font-family: 'Jura',sans-serif;
  font-weight: 300;
}

      