/* .portfolio-img{
    filter: grayscale(1)brightness(0.7);
} */

/* .portfolio-img:hover{
    filter: grayscale(0)brightness(1);
    transition: 0.2s;
} */

/* .nav_bar_portfolio a,.drawer_list a{
	color: white;
    list-style-type: none;
}

.nav_bar_portfolio a:hover{
    color: orangered;
} */

.icon_shadow_portfolio{
    cursor: pointer;
    border: 1px solid black;
    border-radius: 50%;
    box-shadow: 0 0 8px rgba(255, 255, 255, 0.1);
    padding: 8px;
    z-index: 1;
}

.icon_shadow_portfolio:hover{
    background-color: black;
}