*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.Banner{
  /* margin: 0 0 1px; */
  /* padding: 0 0 344px; */
  /* background-color: rgba(210, 236, 255, 0.6); */
  position: relative;
}

.Banner:after {
  content: '';
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* opacity:1; */
  z-index: 1;
}

.logo { 
  animation: fadelogo 10s;
  width: 35rem;
  /* margin-top: 100px; */
  /* -webkit-filter: invert(100%); */
  filter: invert(100%) brightness(1);
  z-index: 2;
}

/* .banner-logo{
  margin-top: 70px;
} */

@keyframes fadelogo {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@media screen  and (max-width:767px){
  .Banner{
    background-size: cover !important;
    height: 500px !important;
  }

  .banner-logo{
    margin-top: -10px;
  }

  .logo{
    width: 15rem;
  }
}


