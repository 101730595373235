* {
	margin: 0px;
	padding: 0;
	box-sizing: border-box;
	/* color: white; */
	/* font-family: 'Quicksand', sans-serif; */
}
.nav_bar_container {
	/* padding: 10px; */
	/* background-color: transparent; */
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 20;
	align-items: center;
	/* margin-bottom: 3; */
}

.nav_bar_ul{
	padding: 0;
	margin: 0;
}

.nav_bar_li{
	padding: 0;
	margin: 0;
}
/* .nav_bar {
	display: flex;
	align-items: center;
	justify-content: space-between;
} */
.nav_bar_img img {
	width: 58px;
	height: 40px;
	/* margin-left: 20px; */
}

/* .nav_bar_ul{
	background-color: rgba(255,255,255, 0.8);
	border-radius: 1px;
} */

/* .nav_bar_li{
	padding: 10px 0px 5px 0px;
} */

/* ul.nav_bar_ul {
	display: flex;
	align-items: center;
	justify-content: space-between;
} */
li.nav_bar_li {
	/* gap: 50px; */
	display: flex;
	align-items: center;
	/* padding-right: 25px; */
}
.nav_bar_li :hover {
	color: orangered;
}
li.nav_bar_li a {
	color: #000;
	text-decoration: none;
	/* font-weight: 600; */
}
/* .nav_bar_li img {
	width: 30px;
	height: 30px;
} */
/* .active {
	display: block;
} */

.icon_shadow{
	display: none;
}
/* .icon_shadow {
	cursor: pointer;
	display: flex;
	display: none;
	padding: 3px;
	border-radius: 3px;
	box-shadow: 2px 4px 2px rgba(0, 0, 0, 0.2);
	align-items: center;
	justify-content: center;
} */
@media screen and (max-width: 768px) {
	/* ul.nav_bar_ul {
		margin-right: 0px;
		display: none;
	} */
	.nav_bar_li {
		/* gap: 30px; */
		display: flex;
		/* padding: 10px; */
		font-size: 12px;
	}

	.nav_bar_li a:hover {
		color: orangered;
	}
	/* .icon_shadow {
		display: flex;
	} */
	/* .nav_bar_ul.active {
		display: flex;
		flex-direction: column;
		position: absolute;
		right: 48px;
		top: 48px;
		z-index: 10;
		box-shadow: 2px 4px 2px rgba(0, 0, 0, 0.2);
		background-color: white;
		padding: 15px;
		border-radius: 4px;
	} */

	.nav_bar_img img {
		width: 48px;
		height: 30px;
		/* margin-left: 20px; */
	}
}

@keyframes slideDown {
	0% { transform: translate(0,-72px); }
	100% { transform: translate(0,0); }
  }
/* 
  @keyframes slideUp {
	0% { transform: translate(0,0px); }
	100% { transform: translate(0,0px); }
  } */

@media screen  and (max-width: 420px) {
	/* ul.nav_bar_ul {
		margin-right: 0px;
	} */
	li.nav_bar_li  {
		/* gap: 15px; */
		display: flex;
		align-items: center;
		
	}
	/* .css-10hburv-MuiTypography-root {
		font-size: 18px !important;
		font-weight: bold !important;
	} */
	.nav_bar_img img {
		width: 46px;
		height: 28px;
		margin-left: 0px;
	}
}
/* .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.MuiDrawer-paperAnchorRight.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
	width: 200px;
	justify-content: center;
	align-items: center;
} */



.nav_bar_ul {
  display: none;
}


.nav_bar_li {
  /* padding: 15px; */
  cursor: pointer;
  font-size: 18px;
}


.icon_shadow {
  cursor: pointer;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  /* background-color: rgba(255,255,255, 0.8); */
  border-radius: 50%;
  padding: 8px;
}


/* .icon_shadow:hover {
  background-color: #f2f2f2; 
} */

/* Set the width of the logo */
/* .nav_bar_img img {
  width: 80px; 
  margin-left: 0px;
} */
