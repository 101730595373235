.footer {
background:#000 !important;
}

.text-uppercase{
  font-family: 'Gruppo',sans-serif;
}
 
.footer_menu {
margin-bottom: 20px;
}
.footer_menu ul {
list-style: none;
text-align: center;
}
.footer_menu ul li{display: inline-block;}
.footer_menu ul li a {
color:#fff;
padding: 0 10px;
-webkit-transition: 0.3s;
transition: 0.3s;
text-decoration: none;
}
.footer_menu ul li a:hover{color:#554c86;}

/*START FOOTER SOCIAL DESIGN*/
.footer_profile{margin-bottom:40px;}
.footer_profile ul{
list-style: outside none none;
margin: 0;
padding: 0
}
.footer_profile ul li{
display: inline-block;
}
/* .links{
  display: flex;
  flex-direction: column;
  align-items: center;
} */
a{
  text-decoration: none !important;
}
@media only screen and (max-width:480px) { 
.footer_profile ul li{margin:2px;}
}
.footer_profile ul li a img{width:40px;}

.footer_profile ul li a {
width: 40px;
height: 40px;
display: block;
text-align: center;
margin-right: 5px;
border-radius: 50%;
line-height: 40px;
box-sizing: border-box;
text-decoration: none;
-webkit-transition: .3s;
transition: .3s;
color: #fff;
}
.footer_copyright {
  margin-bottom: 20px;
  text-transform: uppercase;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #fff;
}
.social-icons {
  background-color: #f6f6f6;
  padding: 9px;
  border-radius: 10px;
  width:50px
}