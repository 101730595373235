/* .ourservices-div {
    animation: slideUp 2s linear;
} */

.ourservices-title{
    font-family: 'Poiret One',sans-serif;
}

.ourservices-content{
    letter-spacing: 1px;
}









@keyframes slideUp {
    0% { 
        opacity: 0;
        bottom: -100px;
        transform: translate(0,150px);
     }
    100% { 
        opacity: 1; 
        bottom: 0px;
        transform: translate(0,0);
        /* z-index: 0; */
    }
  }