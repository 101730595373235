/* .clientele_img_div img{
    width: 40px;
    height: 40px;
} */

.clientele-title{
    font-family: 'Gruppo', sans-serif;
}

.clientele_img_div{
    /* width: 20%; */
    width: 150px; 
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
}

.clientele_img_div img{
    object-fit: cover;
    /* width: 100%; */
    height: 100%;
}

@media only screen and (max-width:992px){
    .clientele_img_div{
        width: 120px;
        height: 120px;
    }
}
@media only screen and (max-width:768px){
    .clientele_img_div{
        width: 130px;
        height: 130px;
    }
}
@media only screen and (max-width:576px){
    .clientele_img_div{
        width: 120px;
        height: 120px;
    }
}