.identity-title{
    font-size: 4rem;
    padding-bottom: 50px;
    font-family: 'Cormorant Garamond',serif;
}

.identity-first-div, .identity-second-div{
    padding: 0 10rem;
}

.identity-first-div{
    padding-bottom: 10rem;
}

.identify-content{
    font-size: 20px;
}

@media only screen and (max-width:768px){
    .identity-title{
        font-size: 3rem;
        padding-bottom: 40px;
    }

    .identity-first-div, .identity-second-div{
        padding: 0 7rem;
    }

    .identity-first-div{
        padding-bottom: 7rem;
    }
}

@media only screen and (max-width:576px){
    .identity-title{
        font-size: 2rem;
        padding-bottom: 40px;
    }

    .identity-first-div, .identity-second-div{
        padding: 0;
    }

    .identity-first-div{
        padding-bottom: 7rem;
    }

    .identify-content{
        font-size: 16px;
    }
}