.clientreviews-carousel {
  /* max-width: 100%; */
  /* margin: 0 auto; */
  width: 100%;
  /* height: 100%; */
  /* min-width: 0;
  overflow: hidden;
  min-height: 0; */
  /* height: auto; */
}

.carousel {
  width: 100%;
  height: auto;
  /* height: 100%; */
  /* width: 0;  */
  /* min-width: 0; */
  /* overflow: hidden;  */
}

.clientreviews-carousel-title{
  font-family: 'Gruppo',sans-serif;
}

/* .clientreviews .carousel-container:nth-child(1) .carousel{
  height: 300px;
} */

.clientreviews-carousel img{
  object-fit: cover !important;
  width: 100%;
  height: 500px !important;
}

.carousel .carousel-slide-text-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .carousel .client_carousel img {
  max-width: 100%;
  height: auto;
} */

/* .carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
} */

/* .carousel-arrow.prev {
  left: 10px;
} */

/* .carousel-arrow.next {
  right: 10px;
} */
/* .carousel .thumbs {
  text-align: center;
  overflow: scroll;
} */
/* .carousel .thumbs::-webkit-scrollbar{
  display: none;
} */

.carousel-container .carousel .control-dots .dot{
          width: 38px;
          height: 3px;
          border-radius: 0;
          box-shadow: none;
}

/* .client_review_img img{
  width: 80%;
  height: auto;

} */

.client-review{
  letter-spacing: 0.5px;
}
