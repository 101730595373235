.about_us{
  margin: 20px;
}

.aboutus-text{
  line-height: 25px;
  font-size: 14px;
}

.aboutus-text{
  letter-spacing: 0.5px;
}

.aboutus-title{
  font-family: 'Gruppo', serif;
}